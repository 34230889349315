/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */

import PropTypes from 'prop-types';
import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import EmptyData from '../common/emptyData';
import LineSpinner from '../spinners/LineSpinner';

import { spendingTypes } from '../../constants/spendings';
import { USD_EXCHANGE_RATE } from '../../constants/exchangesRate';

const totalParser = (amount) => {
  return amount > 0.0
    ? amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : 0.0;
};

function MonthTotal(props) {
  const { titles, totals, isLoading } = props;

  const getSpendingByTitle = (title) => {
    const findSpendingKey = Object.values(spendingTypes).find(
      (spending) => spending.label === title,
    ).key;

    const activeSpending = totals[findSpendingKey];

    if (!activeSpending || activeSpending <= 0) {
      return <EmptyData height="1.5rem" />;
    }

    return (
      <span className="h2 mb-0 col-4">
        {`$ ${totalParser(activeSpending)}`}
      </span>
    );
  };

  return (
    <Container fluid>
      <Row className="g-3">
        {titles.map((title) => (
          <Col md={4} key={title}>
            <Card>
              <Card.Header>
                <h4 className="card-header-title">{`${title} (${USD_EXCHANGE_RATE})`}</h4>
              </Card.Header>
              {isLoading ? (
                <LineSpinner name="Analyzing..." />
              ) : (
                <Card.Body>{getSpendingByTitle(title)}</Card.Body>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

MonthTotal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  titles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  totals: PropTypes.shape({
    totalByLifetime: PropTypes.number,
    totalBycurrentYear: PropTypes.number,
    totalByLastMonth: PropTypes.number,
    totalCurrentSpending: PropTypes.number,
  }).isRequired,
};

export default MonthTotal;
