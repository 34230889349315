// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LineSpinner from '../spinners/LineSpinner';

function LoadingModal(props) {
  const { t } = useTranslation();
  const { children } = props;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h1 className="header-title text-center">
              {t('components.modals.loadingModal.title')}
            </h1>
          </div>
        </div>
      </div>
      <div className="modal-body d-flex flex-column">
        <div className="col">
          <h4 className="text-center">
            <LineSpinner name="" />
            {t('components.modals.loadingModal.content')}
            {children}
          </h4>
        </div>
      </div>
    </>
  );
}

export default LoadingModal;

LoadingModal.propTypes = {
  children: PropTypes.node,
};
