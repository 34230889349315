import {
  FETCH_TRANSACTIONS, FETCH_TOTAL_SPENDING, SET_SPENDING_STATE, FETCH_CHART_TRANSACTIONS,
} from '../../actions/types';

const INITIAL_STATE = {
  loading: false,
  transactions: null,
  chartTransactions: null,
  total: null,
};

export default function transactionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SPENDING_STATE:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case FETCH_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case FETCH_CHART_TRANSACTIONS:
      return { ...state, chartTransactions: action.payload };
    case FETCH_TOTAL_SPENDING:
      return { ...state, total: action.payload };
    default:
      return state;
  }
}
