/* eslint-disable indent */
// eslint-disable-next-line import/prefer-default-export
export const spendingTypes = {
  lifetimeTotal: {
    label: 'Lifetime total spending',
    key: 'totalByLifetime',
  },
  currentSpending: {
    label: 'Current Spending',
    key: 'totalCurrentSpending',
  },
  currentYear: {
    label: 'Yearly Spending',
    key: 'totalBycurrentYear',
  },
  lastMonth: {
    label: 'Last Month',
    key: 'totalByLastMonth',
  },
};
