/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAbbreviaton, getVendorsColors } from '../../../helpers/colors';

export const baseDropdownEntities = {
  vendors: 'vendors',
};

function BaseMultipleOtionsDropdown({ optionsList, selectedList, entity, onToggleSelection }) {
  const isSelected = (option) => selectedList?.includes(option);

  const itemStyles = (activeItem) => ({
    marginRight: 5,
    padding: 3,
    borderColor: 'black',
    borderRadius: 3,
    background: getVendorsColors(activeItem),
    color: '#f0f0f0',
  });

  return (
    <DropdownButton
      className="dropdown-button-style"
      as={ButtonGroup}
      id="dropdown-basic-button"
      defaultChecked
      title={
        selectedList?.length ? (
          <div>
            {selectedList.map(
              // eslint-disable-next-line max-len
              (activeItem) => (
                <span style={itemStyles(activeItem)} key={activeItem}>
                  {entity === baseDropdownEntities.vendors ? getAbbreviaton(activeItem) : activeItem}
                </span>
              ),
            )}
          </div>
        ) : (
          'Select an Option'
        )
      }
    >
      {optionsList.map((vendor) => (
        <Dropdown.Item key={vendor} onClick={() => onToggleSelection(vendor)} active={isSelected(vendor)}>
          {isSelected(vendor) ? '☑️ ' : '☐ '} {vendor}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default BaseMultipleOtionsDropdown;

BaseMultipleOtionsDropdown.propTypes = {
  selectedList: PropTypes.arrayOf(PropTypes.string),
  entity: PropTypes.string,
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleSelection: PropTypes.func.isRequired,
};
