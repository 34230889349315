// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../actions/modalActions';
import { FEED_MODAL } from '../../actions/types';
import reportRequest from '../../services/lambdas/reports';

function PageHeaderTitle(props) {
  const dispatch = useDispatch();
  const filtersState = useSelector((state) => state.filters);
  const { title, pretitle, actions = {} } = props;

  const openLoadingModal = async () => {
    dispatch(
      toggleModal({
        type: 'loading',
      }),
    );

    const activeFilters = {
      ...(filtersState.integrationId && {
        integrationId: filtersState.integrationId,
      }),
      ...(filtersState.vendors && {
        vendors: filtersState.vendors.map((vendor) => vendor._id),
      }),
    };

    let filters = filtersState.inUse ? activeFilters : {};

    filters = {
      startDate: filtersState.start,
      endDate: filtersState.end,
      ...filters,
    };

    try {
      const retries = 4;
      const initialRetryDelay = 1000;
      const reportResponse = await reportRequest(
        actions.organizationId,
        filters,
        retries,
        initialRetryDelay,
      );

      // eslint-disable-next-line max-len
      const pdfBlob = new Blob([reportResponse.data], {
        type: 'application/pdf',
      });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      window.open(pdfUrl);

      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = 'report.pdf';

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);

      setTimeout(() => URL.revokeObjectURL(pdfUrl), 1000);
      dispatch(toggleModal('close'));
    } catch (err) {
      dispatch({
        type: FEED_MODAL,
        payload: {
          type: 'fail',
          from: 'Report generation',
          err: {
            message:
              err.status === 503
                ? 'Error generating report, try again later'
                : err.message,
          },
        },
      });
    }
  };

  return (
    <div className="header mt-md-5">
      <div className="header-body">
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="header-pretitle">{pretitle}</h6>
            <h1 className="header-title">{title}</h1>
          </div>
          <div>
            {actions?.downloadBtn ? (
              <div className="align-self-center">
                <Link
                  onClick={openLoadingModal}
                  className="btn btn-sm btn-primary mr-2 "
                >
                  {actions?.downloadBtn}
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeaderTitle;
PageHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    filtersLabel: PropTypes.string,
    downloadBtn: PropTypes.string,
    organizationId: PropTypes.string,
  }),
};
